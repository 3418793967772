import React from "react"
import { Column, Container, Row } from "../../components/Grid"
import Hero from "../../components/Hero"
import Layout from "../../components/Layout"
import Resource from "../../components/Resource"
import Section from "../../components/Section"
import SEO from "../../components/Seo"
import SubFooterImage from "../../images/theme/img-subfooter-caregivers.jpg"
import Icon from "../../components/Icon"
import "./caregiversStyles.scss"

const Caregivers = () => {
  return (
    <Layout className="caregivers">
      <SEO title="Caregivers" />
      {/***************  HERO ***************/}
      <Section className="hero">
        <Hero
          title="Caregivers"
          infographic="img-hero-infographic-caregivers.png"
          body="Use positive interactions to help prepare children and teens to make healthy decisions and inform them about the risks of substance misuse."
          image="img-hero-caregivers.jpg"
        />
      </Section>

      {/***************  CAREGIVER RESOURCE ***************/}
      <Section className="resource-container midgray-background caregiver-guide-section">
        <Column span={12}>
          <Resource
            halfLabel
            label="CAREGIVER GUIDE"
            image="thumbnail-caregiver-guide.jpg"
            title="Sparking critical conversations with children and teens"
            description={
              <p className="mt-2 mb-2">
                Find productive ways to initiate and maintain ongoing discussions with children and teens regarding substance misuse. Explore key background information, activity ideas, and conversation starters based on student grade level with this comprehensive guide for caregivers.
              </p>
            }
            actions={[
              {
                label: "Caregiver Guide",
                path: "/docs/CVS-Pharma-DoK-CaregiverGuide.pdf",
                type: "download",
                locked: false,
              },
              // {
              //   label: "Educator Guide",
              //   path: "/docs/CVS-Pharma-DLB-Responding_to_Peer_Pressure-Pharmacists.pdf",
              //   type: "download",
              //   size: "PDF 1,000,000 GB",
              //   locked: false
              // }
            ]}
            horizontal
          />
        </Column>
      </Section>

      {/***************  ADDITIONAL CAREGIVER RESOURCE ***************/}
      <Section className="resource-container midgray-background addl-resources-section">
        <Column span={12}>
          <Resource
            halfLabel
            label="additional caregiver resources"
            image="caregivers-photo-addl-resources.jpg"
            description={
              <p>
                There are a variety of resources available to support caregivers in critical conversations with the young people in their lives.
              </p>
            }
            horizontal
          >
            <Row>
              <Column span={6} className="lh-i">
                <div className="mb-1"><b>Community Resources</b></div>
                <ul>
                  <li style={{marginBottom: '0.5rem'}}>Local Pharmacists</li>
                  <li style={{marginBottom: '0.5rem'}}>Educator</li>
                  <li style={{marginBottom: '0.5rem'}}>School Counselor</li>
                  <li style={{marginBottom: '0.5rem'}}>Pediatrician/ Family Doctor</li>
                  <li style={{marginBottom: '0.5rem'}}>Mental Health Provider</li>
                  <li style={{marginBottom: '0.5rem'}}>Other Healthcare Professional</li>
                </ul>
              </Column>
              <Column span={6}>
                <div className="mb-1"><b>Online Resources</b></div>
                <div style={{display: 'flex'}}>
                  <Icon className="fill-red mw-24" name="rightarrow" marginRight /> <a style={{ color: '#CC0000', marginBottom: '0.5rem'}}href="https://www.samhsa.gov/" target="_blank">SAHMSA</a>
                </div>
                <div style={{display: 'flex'}}>
                  <Icon className="fill-red mw-24" name="rightarrow" marginRight /> <a style={{color: '#CC0000', marginBottom: '0.5rem'}} href="http://www.drugfree.org/" target="_blank">Partnership for Drug-Free Kids</a>
                </div>
                <div style={{display: 'flex'}}>
                  <Icon className="fill-red mw-24" name="rightarrow" marginRight /> <a style={{color: '#CC0000', marginBottom: '0.5rem'}} href="http://teens.drugabuse.gov/" target="_blank">NIDA for Teens</a>
                </div>
                <div style={{display: 'flex'}}>
                  <Icon className="fill-red mw-24" name="rightarrow" marginRight /> <a style={{color: '#CC0000', marginBottom: '0.5rem'}} href="https://www.betterwithout.it/" target="_blank">Better without It</a>
                </div>
                <div style={{display: 'flex'}}>
                  <Icon className="fill-red mw-24" name="rightarrow" marginRight /> <a style={{color: '#CC0000'}} href="https://ncsacw.acf.hhs.gov/" target="_blank">National Center on Substance Abuse and Child Welfare</a>
                </div>
              </Column>
            </Row>
          </Resource>
        </Column>
      </Section>

      {/***************  ABOVE FOOTER CALLOUT ***************/}
      <Section className="subfooter">
        <Container>
          <Row>
            <Column span={4}>
              <img
                src={SubFooterImage}
                className="subfooter-image"
                alt="Title: Facts, Info etc."
              />
            </Column>
            <Column span={8}>
              <h3>Facts:</h3>
              <p>
                Only 57.2 percent of adolescents talk to their parents about the dangers of substance use.
              </p>
              <p>
                <a href="https://www.samhsa.gov/data/sites/default/files/report_3380/ShortReport-3380.html#:~:text=As%20previously%20noted%2C%20the%20majority,program%20outside%20of%20school%20(Figure" target="_blank"><em>-Substance Abuse and Mental Health Services Administration (SAMHSA)</em></a>
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Caregivers
